@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.link {
  cursor: pointer;
}

.shimmer {
  background-color: #f6f6f6;
  height: 25px;
  margin-bottom: 5px;
  animation: shimmer 0.3s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

/* ADDING THIS CSS MAKE SIDEBAR RESPONSIVE */
/* Add this to your CSS or SCSS file */
@media (max-width: 640px) {
  .mobile-menu-open {
    transform: translateX(0) !important;
  }

  .bg-slate-100 {
    transition: transform 0.3s ease-out;
    transform: translateX(-100%);
  }

  .bg-slate-100,
  .text-grey-500,
  .h-screen,
  .flex-1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .border-gray-400 {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background-color: #ccc;
  }
}
